<template>
  <b-form-group
    :label="`${label ? (is_required || required ? label + '*' : label) : ''}`"
    :class="
      component_key === 'dash_widget'
        ? 'dash-cal fixed-position-date-picker'
        : component_key === 'dash_home'
        ? 'dash-cal '
        : 'date-report fixed-position-date-picker'
    "
  >
    <date-range-picker
      v-model="dateRange"
      @update="updateValues"
      :auto-apply="!options || !options.is_time ? true : false"
      :single-date-picker="options && options.single_date ? true : false"
      :opens="
        component_key === 'dash_widget'
          ? 'right'
          : component_key === 'dash_home'
          ? 'right'
          : 'left'
      "
      :maxDate="is_coming_date ? null : getCurrentTime()"
      :minDate="is_coming_date ? getCurrentTime() : null"
      :disabled="isEditable"
      :time-picker-increment="1"
      :ranges="
        options && options.single_date
          ? false
          : is_coming_date
          ? futureRanges
          : customRanges
      "
      :timePicker="options && options.is_time ? true : false"
    >
      <template #input="picker">
        <span v-if="options && options.single_date">{{
          `${
            picker.startDate
              ? `${DateTimeConvert(
                  picker.startDate,
                  constants.DATE_TIME_FORMAT
                )}`
              : ""
          }`
        }}</span>
        <span
          v-if="
            dateRange &&
            dateRange.startDate &&
            (!options || !options.single_date)
          "
        >
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          {{
            `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
          }}
        </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range), getRange(name)"
            >
              {{ name }}
              <!-- <b>{{ $t(`dateRange.${name}`) }}</b> -->
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker>
  </b-form-group>
</template>

<script>
import constants from "@/utils/constants";
import { BFormGroup } from "bootstrap-vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import moment from "moment";

export default {
  name: "SlotsDemo",
  components: { DateRangePicker, BFormGroup },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      oldDateRange: { startDate: new Date(), endDate: new Date() },
      customRanges: this.getCustomRanges(true),
      futureRanges: this.getCustomFutureRanges(true)
    };
  },
  mounted() {
    // console.log("in date range compo: ", this.dateRange, this.parameter);
    if (this.component_key === "dash_home") {
      return;
    }
    if (this.component_key !== "dash_widget") {
      this.parameterChanged(this.dateRange);
    }
    if (this.value && this.value.startDate && this.value.endDate) {
      this.parameterChanged(this.value);
    } else if (this.value && typeof this.value == "String") {
      const dates = this.value.split(" to ");

      this.dateRange.startDate = new Date(dates[0]);
      // this.dateRange.startDate = this.dateRange.startDate.toLocaleString();
      this.dateRange.endDate = new Date(dates[1]);
      this.parameterChanged(this.dateRange);
      // this.dateRange.endDate = this.dateRange.endDate.toLocaleString();
    } else {
      //  this.dateRange.startDate = this.dateRange.startDate.setHours(0, 0, 0);
      this.parameterChanged(this.dateRange);
    }
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  // props: ["selectedMenu", "unit_id"],
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "parameterDataChanged",
    "value",
    "isEditable",
    "component_key",
    "options",
    "is_required",
    "required",
    "is_custom",
    "is_coming_date"
  ],
  watch: {
    dateRange(newVal, oldDate) {
      this.oldDateRange = oldDate;
    }
    // unit_id(val) {
    //   if (!val) {
    //     this.dateRange = { startDate: new Date(), endDate: new Date() };
    //     this.$emit("input", this.dateRange);
    //   }
    // }
  },
  methods: {
    updateValues(e) {
      if (
        new Date().getTime() < e.endDate.getTime() ||
        new Date().getTime() < e.startDate.getTime() ||
        e.startDate.getTime() > e.endDate.getTime()
      ) {
        console.log("error");
      }
      this.parameterChanged(e);
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(
        date,
        format
          ? format
          : this.component_key === "dash_widget"
          ? constants.DATE_TIME_FORMAT
          : constants.DATE_FORMAT
      );
    },
    getRange(range) {
      this.$emit("emitDataToParent", range);
    },
    parameterChanged() {
      if (this.component_key !== "dash_widget" && this.dateRange.startDate) {
        const startDate = moment(this.dateRange.startDate);
        startDate.hours(0);
        startDate.minutes(0);
        startDate.seconds(0);
        this.dateRange.startDate = startDate;
      }
      const currentTime = moment();
      const currentHour = currentTime.hour();
      const currentMinute = currentTime.minute();
      const currentDay = currentTime.date();
      const currentMonth = currentTime.month();
      const selectedHour = moment(this.dateRange.endDate).hour();
      const selectedMinute = moment(this.dateRange.endDate).minute();
      const selectedDay = moment(this.dateRange.endDate).date();
      const selectedMonth = moment(this.dateRange.endDate).month();
      // this.dateRange.startDate = new Date(this.dateRange.startDate);
      this.dateRange.startDate = this.convertDatePerTimezone(
        this.dateRange.startDate
      );
      if (
        this.dateRange.endDate &&
        (this.component_key !== "dash_widget" ||
          (((selectedDay != currentDay && currentMonth == selectedMonth) ||
            (selectedDay == currentDay && currentMonth != selectedMonth) ||
            (selectedDay != currentDay && currentMonth != selectedMonth)) &&
            currentHour == selectedHour &&
            currentMinute >= selectedMinute &&
            currentMinute <= selectedMinute + 3))
      ) {
        const endDate = moment(this.dateRange.endDate);
        endDate.hours(23);
        endDate.minutes(59);
        endDate.seconds(59);
        this.dateRange.endDate = endDate;
      }

      if (this.dateRange.endDate > new Date() && !this.is_coming_date) {
        this.dateRange.endDate = new Date();
      }
      //  this.dateRange.endDate = new Date(this.dateRange.endDate);
      this.dateRange.endDate = this.convertDatePerTimezone(
        this.dateRange.endDate
      );
      if (!this.value) {
        setTimeout(() => {
          this.$emit(
            "input",
            `${this.dateRange.startDate} to ${this.dateRange.endDate}`
          );
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss">
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-report {
  .daterangepicker.opensleft {
    right: 2vh;
  }
}
.dash-cal {
  .daterangepicker.opensright {
    left: auto !important;
    right: auto !important;
  }
}
.dark-layout {
  .dash-cal {
    .reportrange-text {
      border: 1px solid #404656 !important;
    }
  }
}
.dash-cal {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }
}
.text-black {
  color: #000;
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: auto !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
//   .date-report {
//     .daterangepicker.opensleft[data-v-1ebd09d2] {
//       left: auto;
//       right: auto;
//     }
//   }
// }
.fixed-position-date-picker .daterangepicker {
  position: fixed;
}
@media only screen and (max-width: 991px) {
  .calendars {
    max-width: fit-content;
    flex-wrap: nowrap !important;
    max-height: 40vh !important;
    overflow: overlay !important;
  }
  .fixed-position-date-picker .daterangepicker {
    width: 350px;
    .drp-calendar.right {
      padding: 8px 8px 8px 8px;
    }
  }
  .date-report {
    .daterangepicker.opensleft {
      right: 2vh;
    }
  }
}
.dash-cal {
  select.form-control:not([multiple="multiple"]) {
    background-image: none;
  }
  select.hourselect {
    padding: 9px 9px 9px 14px !important;
  }

  select.minuteselect {
    padding: 9px 9px 9px 14px !important;
  }
}
.dark-layout {
  .dash-cal {
    select.form-control:not([multiple="multiple"]) {
      background-image: none;
    }
    select.hourselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }

    select.minuteselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }
  }
}
</style>
<style lang="scss">
@media only screen and (max-width: 991px) {
  .dash-cal.fixed-position-date-picker .daterangepicker.opensright {
    left: auto !important;
    right: auto !important;
    max-height: 42vh;
    overflow: auto;
  }
}
</style>
