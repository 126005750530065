var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{class:_vm.component_key === 'dash_widget'
      ? 'dash-cal fixed-position-date-picker'
      : _vm.component_key === 'dash_home'
      ? 'dash-cal '
      : 'date-report fixed-position-date-picker',attrs:{"label":("" + (_vm.label ? (_vm.is_required || _vm.required ? _vm.label + '*' : _vm.label) : ''))}},[_c('date-range-picker',{attrs:{"auto-apply":!_vm.options || !_vm.options.is_time ? true : false,"single-date-picker":_vm.options && _vm.options.single_date ? true : false,"opens":_vm.component_key === 'dash_widget'
        ? 'right'
        : _vm.component_key === 'dash_home'
        ? 'right'
        : 'left',"maxDate":_vm.is_coming_date ? null : _vm.getCurrentTime(),"minDate":_vm.is_coming_date ? _vm.getCurrentTime() : null,"disabled":_vm.isEditable,"time-picker-increment":1,"ranges":_vm.options && _vm.options.single_date
        ? false
        : _vm.is_coming_date
        ? _vm.futureRanges
        : _vm.customRanges,"timePicker":_vm.options && _vm.options.is_time ? true : false},on:{"update":_vm.updateValues},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [(_vm.options && _vm.options.single_date)?_c('span',[_vm._v(_vm._s(("" + (picker.startDate ? ("" + (_vm.DateTimeConvert( picker.startDate, _vm.constants.DATE_TIME_FORMAT ))) : ""))))]):_vm._e(),(
          _vm.dateRange &&
          _vm.dateRange.startDate &&
          (!_vm.options || !_vm.options.single_date)
        )?_c('span',[_vm._v(" "+_vm._s(("" + (picker.startDate ? ("" + (_vm.DateTimeConvert(picker.startDate))) : "")))+" "+_vm._s(("" + (picker.endDate ? (" - " + (_vm.DateTimeConvert(picker.endDate))) : " ")))+" ")]):_vm._e()]}},{key:"date",fn:function(data){return [_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm._f("dateCell")(data.date)))])]}},{key:"ranges",fn:function(ranges){return [_c('div',{staticClass:"ranges"},[_c('ul',_vm._l((ranges.ranges),function(range,name){return _c('li',{key:name,on:{"click":function($event){ranges.clickRange(range), _vm.getRange(name)}}},[_vm._v(" "+_vm._s(name)+" ")])}),0)])]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }