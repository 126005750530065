var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":12}},[_c('b-form-group',{staticClass:"unit-search-input"},[_c('v-select',{staticClass:"unit-w",attrs:{"clearable":false,"disabled":_vm.isDisabled,"options":_vm.unitList,"placeholder":_vm.unit ? '' : _vm.$t('UnitTrack.SearchUnit'),"label":"name","loading":_vm.showLoading && _vm.componentKey == 'dash_widgets',"reduce":function (option) { return option.id; },"menu-props":"auto"},on:{"search":_vm.search,"input":function (e) {
            _vm.$emit('input', e);
          }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
          var name = ref.name;
          var icon = ref.icon;
return [_c('b-avatar',{staticClass:"align-middle mr-50 unit-filter-avatar",attrs:{"src":icon,"size":"24","variant":"light-primary"}}),_c('span',{staticClass:"unit-search-w"},[_vm._v(" "+_vm._s(name))])]}},{key:"option",fn:function(ref){
          var name = ref.name;
          var icon = ref.icon;
          var archived = ref.archived;
return [_c('b-avatar',{staticClass:"align-middle mr-50",attrs:{"src":icon,"variant":"light-primary","size":"24"}}),_c('span',{staticClass:"unit-search-w"},[_vm._v(_vm._s(name)+" "),(archived)?_c('span',[_vm._v(_vm._s(_vm.$t("unit.AlreadyAllocationMessage")))]):_vm._e()])]}}]),model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }