<template>
  <div class="maintenance-datepicker" :class="isCancel ? 'd-flex ' : ''">
    <b-form-group
      class="maintenance-create"
      :class="isCancel ? 'min-width-300' : ''"
      :label="`${label ? (is_required || required ? label + '*' : label) : ''}`"
    >
      <date-range-picker
        v-model="dateRange"
        @update="updateValues"
        :auto-apply="false"
        :single-date-picker="options && options.single_date ? true : false"
        :opens="options && options.opens ? options.opens : 'right'"
        :disabled="isEditable"
        :time-picker-increment="1"
        :placeholder="placeholder"
        :minDate="minDate ? minDate : ''"
        :maxDate="maxDate ? maxDate : ''"
        :timePicker="options && options.is_time ? true : false"
      >
        <template #input="picker">
          <span v-if="dateRange && dateRange.startDate">
            <span v-if="options && options.single_date">{{
              `${
                picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""
              }`
            }}</span>
            <span v-else>
              {{
                `${
                  picker.startDate
                    ? `${DateTimeConvert(
                        picker.startDate,
                        constants.DATE_TIME_FORMAT
                      )}`
                    : ""
                }${
                  picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "
                }`
              }}</span
            >
          </span>
          <span v-else class="place-holder-date"
            >{{ placeholder ? placeholder : $t("unit.SelectDate") }}
          </span>
        </template>
        <!--    date slot-->
        <template #date="data">
          <span class="small">{{ data.date | dateCell }}</span>
        </template>
        <div slot="footer" slot-scope="data" class="slot" v-if="isCancel">
          <div style="margin-left: auto">
            <a
              @click="dateSets(data)"
              class="btn btn-primary btn-history btn-sm m-1"
              >{{$t("datepicker.Cancel")}}</a
            >

            <a
              @click="data.clickApply"
              v-if="!data.in_selection"
              class="btn btn-primary btn-history btn-sm m-1"
              >{{$t("datepicker.Apply")}}</a
            >
          </div>
        </div>
      </date-range-picker>
    </b-form-group>
    <b-button
      v-if="isCancel"
      style="height: 37px; width: 37px;padding: 0;"
      id="clearDate"
      @click="clear()"
      variant="outline-secondary"
      ><feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer clear-date"
      />
      </b-button
    >
  </div>
</template>

<script>
import constants from "@/utils/constants";
import { BFormGroup, BButton } from "bootstrap-vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import moment from "moment";
import { max_value } from "@/@core/utils/validations/validations";

export default {
  name: "SlotsDemo",
  components: { DateRangePicker, BFormGroup, BButton },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      }
    };
  },
  mounted() {
    this.dateSets();
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  // props: ["selectedMenu", "unit_id"],
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "parameterDataChanged",
    "value",
    "isEditable",
    "component_key",
    "options",
    "is_required",
    "required",
    "is_custom",
    "placeholder",
    "minDate",
    "maxDate",
    "isCancel"
  ],
  watch: {
    value() {
      this.dateSets();
    }
  },
  methods: {
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm A");
    },
    updateValues(e) {
      if (this.options.single_date) {
        this.dateRange.endDate = this.convertDatePerTimezone(
          this.dateRange.endDate
        );
        this.$emit(
          "input",
          `${this.convertDatePerTimezone(this.dateRange.startDate)}`
        );
      } else {
        if (this.dateRange.startDate) {
          const startDate = moment(this.dateRange.startDate);
          startDate.hours(0);
          startDate.minutes(0);
          startDate.seconds(0);
          this.dateRange.startDate = startDate;
        }

        if (this.dateRange.endDate) {
          const endDate = moment(this.dateRange.endDate);

          const now = moment();
          const isToday = endDate.isSame(now, "day");
          const isThisWeek = endDate.isSame(now, "week");
          const isThisMonth = endDate.isSame(now, "month");

          if (isToday || isThisWeek || isThisMonth) {
            endDate.hours(now.hours());
            endDate.minutes(now.minutes());
            endDate.seconds(now.seconds());
          } else {
            endDate.hours(23);
            endDate.minutes(59);
            endDate.seconds(59);
          }

          this.dateRange.endDate = endDate;
        }
        this.$emit(
          "input",
          `${this.convertDatePerTimezone(
            this.dateRange.startDate
          )} to ${this.convertDatePerTimezone(this.dateRange.endDate)}`
        );
      }
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTimeWithoutTimezone(
        date,
        constants.DATE_TIME_FORMAT
      );
    },
    clear(data) {
      this.dateRange = {
        startDate: null,
        endDate: null
      };
      if (data) {
        data.clickCancel();
      }
      this.updateValues();
    },
    dateSets(data = null) {
      if (this.value && this.value.startDate && this.value.endDate) {
        this.dateRange = {
          startDate: this.value.startDate,
          endDate: this.value.endDate
        };
      } else if (
        this.value &&
        typeof this.value == "string" &&
        this.value.includes("to")
      ) {
        const dates = this.value.split(" to ");
        this.dateRange = {
          startDate: dates[0],
          endDate: dates[1]
        };
        // this.dateRange.endDate = this.dateRange.endDate.toLocaleString();
      } else if (this.value) {
        // this.dateRange = {
        //   startDate: this.getCurrentTime(this.value),
        //   endDate: this.getCurrentTime(this.value)
        // };
      } else {
        this.dateRange = {
          startDate: null,
          endDate: null
        };
      }
      if (data) {
        if (
          this.value &&
          typeof this.value == "string" &&
          this.value.includes("to")
        ) {
          const dates = this.value.split(" to ");
          this.dateRange = {
            startDate: dates[0],
            endDate: dates[1]
          };
          data.clickCancel();
          // this.dateRange.endDate = this.dateRange.endDate.toLocaleString();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-report {
  .daterangepicker.opensleft {
    right: 2vh;
  }
}
.dash-cal {
  .daterangepicker.opensright {
    left: auto !important;
    right: auto !important;
  }
}
.text-black {
  color: #000;
}
.daterangepicker .drp-calendar.left {
  padding-left: 5px !important;
}
.daterangepicker .drp-calendar.right {
  padding-left: 5px !important;
}
// .daterangepicker.openscenter {
//   left: auto !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
//   .date-report {
//     .daterangepicker.opensleft[data-v-1ebd09d2] {
//       left: auto;
//       right: auto;
//     }
//   }
// }
.fixed-position-date-picker .daterangepicker {
  position: fixed;
}
@media only screen and (max-width: 991px) {
  .fixed-position-date-picker .daterangepicker {
    width: 280px;
    .drp-calendar.right {
      padding: 8px 0 8px 8px;
    }
  }
  .date-report {
    .daterangepicker.opensleft {
      right: 2vh;
    }
  }
}
.dash-cal {
  select.form-control:not([multiple="multiple"]) {
    background-image: none;
  }
  select.hourselect {
    padding: 9px 9px 9px 14px !important;
  }

  select.minuteselect {
    padding: 9px 9px 9px 14px !important;
  }
}
.dark-layout {
  .dash-cal {
    select.form-control:not([multiple="multiple"]) {
      background-image: none;
    }
    select.hourselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }

    select.minuteselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }
  }
}
</style>
<style lang="scss">
#clearDate {
  height: 37px;
  width: 37px;
  border-left: none !important;
  border-radius: 0rem 0.358rem 0.358rem 0rem;
}

.daterangepicker.opensright {
  max-height: 50vh !important;
  overflow: auto !important;
  // min-width: 100px !important;
  flex-direction: column !important;
}
.min-width-300 {
  min-width: 250px;
}
@media only screen and (min-width: 668px) {
  .maintenance-datepicker {
    .daterangepicker.ltr .drp-calendar.left .calendar-table,
    .daterangepicker.ltr .drp-calendar.right .calendar-table {
      padding-left: 9px;
    }
    .calendars {
      flex-wrap: wrap;
    }
  }
}

@media only screen and (max-width: 991px) {
  .dash-cal.fixed-position-date-picker .daterangepicker.opensright {
    left: auto !important;
    right: auto !important;
    max-height: 42vh;
    overflow: auto;
  }
  .min-width-300 {
    min-width: 180px;
  }
  .maintenance-datepicker {
    .calendars {
      flex-wrap: wrap !important;
    }
  }
}
.maintenance-datepicker {
  .slot {
    border-top: 1px solid white !important;
  }
  .reportrange-text span {
    padding-top: 2px;
  }
  .calendars {
    flex-wrap: nowrap;
  }
}
.dark-layout{
  .maintenance-datepicker {
    .maintenance-create + .btn.btn-outline-secondary {
      background-color: var(--dark-bg);
    }
  }
}
.form-group.maintenance-create {
  margin-bottom: 0px;
}
.maintenance-create {
  select.hourselect {
    padding: 9px 9px 9px 14px !important;
  }

  select.minuteselect {
    padding: 9px 9px 9px 14px !important;
  }
}
.dark-layout {
  .maintenance-create {
    select.form-control:not([multiple="multiple"]) {
      background-image: none !important;
    }
    select.hourselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }

    select.minuteselect {
      padding: 9px 9px 9px 14px !important;
      background-color: var(--dark-bg);
    }
  }
  #clearDate {
    height: 37px;
    width: 37px;
    border: 1px solid #404656 !important;
    border-left: none !important;
    border-radius: 0rem 0.358rem 0.358rem 0rem;
    border-color: #404656 !important;
  }
  .maintenance-create {
    .vue-daterange-picker {
      .form-control {
        border: 1px solid #404656 !important;
      }
    }
  }
}
#clearDate {
  height: 37px;
  width: 37px;
  border: 1px solid #d8d6de !important;
  border-left: none !important;
  border-radius: 0rem 0.358rem 0.358rem 0rem;
  border-color: #d8d6de !important;
}
.maintenance-create {
  .vue-daterange-picker {
    .form-control {
      border-radius: 0.357rem 0rem 0rem 0.357rem !important ;
      border: 1px solid #d8d6de !important;
    }
  }
}
.min-width-10 {
  min-width: 300px;
}
.btn-history {
  padding-top: 12px;
}
.daterangepicker .ranges li {
  white-space: nowrap;
}
.clear-date{
  color: #6e6b7b;
}
.dark-layout{
  .clear-date{
      color: #b4b7bd;
  }
}
</style>
