<template>
  <div class="d-flex dash-chart-date-filter">
    <div class="dateRange-dash">
      <b-form-group :label="$t('dateRangeDash.label')">
        <v-select
          id="vue-select"
          v-model="rangeValue"
          :options="component_value"
          label="key"
          :clearable="false"
          :placeholder="$t('dateRangeDash.placeholder')"
          :reduce="(option) => option.value"
          @input="onRangeChange"
          menu-props="auto"
        >
          <template #option="{ key }">
            <div class="option">
              <span>{{ key }}</span>
            </div>
          </template>
          <template #selected-option="{ key }">
            <div class="selected-option">
              <span> {{ key }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
export default {
  props: [
    "name",
    "desc",
    "parameterDataChanged",
    "value",
    "component_value",
    "isRequired",
    "range"
  ],

  components: {
    vSelect,
    BFormGroup
  },

  data: function () {
    return {
      ranges: this.getCustomRanges1(true),
      rangeValue: this.range || "Today",
      dateRange: {
        startDate: moment().subtract(7, "days").startOf("day"),
        endDate: new Date()
      }
    };
  },

  computed: {},
  mounted() {
    this.onRangeChange(this.rangeValue);
  },
  watch: {},
  methods: {
    getRange() {
      this.ranges = validators.getCustomRanges1(true);
    },
    onRangeChange(e) {
      this.date = e;
      try {
        this.$emit("input", e);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.dateRange-dash {
  width: 100%;
}
</style>
