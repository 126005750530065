<template>
  <b-row>
    <b-col :md="12">
      <b-form-group class="unit-search-input">
        <v-select
          class="unit-w"
          v-model="unit"
          :clearable="false"
          :disabled="isDisabled"
          :options="unitList"
          @search="search"
          :placeholder="unit ? '' : $t('UnitTrack.SearchUnit')"
          label="name"
          :loading="showLoading && componentKey == 'dash_widgets'"
          @input="
            (e) => {
              $emit('input', e);
            }
          "
          :reduce="(option) => option.id"
          menu-props="auto"
        >
          <template #selected-option="{ name, icon }">
            <b-avatar
              :src="icon"
              size="24"
              variant="light-primary"
              class="align-middle mr-50 unit-filter-avatar"
            />

            <span class="unit-search-w"> {{ name }}</span>
          </template>
          <template #option="{ name, icon, archived }">
            <b-avatar
              :src="icon"
              variant="light-primary"
              size="24"
              class="align-middle mr-50"
            />

            <span class="unit-search-w"
              >{{ name }}
              <span v-if="archived">{{
                $t("unit.AlreadyAllocationMessage")
              }}</span></span
            >
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BAvatar, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BAvatar,
    BSpinner
  },
  data() {
    return {
      unit: "",
      unitList: [],
      timeoutId: null,
      showLoading: false
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.account_id) {
      this.getAllUnitList(this.$route.query.account_id);
      if (this.$route.query && this.$route.query.id) {
        this.unit = this.$route.query.id;
        this.$emit("input", this.$route.query.id);
      }
    } else {
      if (this.accountId) {
        this.getAllUnitList(this.accountId);
      } else {
        this.getAllUnitList(localStorage.getItem("ACCOUNT_ID"));
        if (this.$route.query && this.$route.query.id) {
          this.unit = this.$route.query.id;
          this.$emit("input", this.$route.query.id);
        }
      }
      if (this.value && this.value.id) {
        this.unit = this.value.id;
      } else if (this.value) {
        this.unit = this.value;
      }
    }
  },
  watch: {
    accountId(val) {
      this.getAllUnitList(this.accountId);
      this.$emit("input", "");
      this.unit = "";
    },
    value(val) {
      if (val && val.id) {
        this.unit = val.id;
      } else {
        this.unit = val;
      }
    },
    $route(to, from) {
      if (this.componentKey == "dash_widgets") {
        return;
      }
      const { query: toQuery } = to;
      const { query: fromQuery } = from;

      if (
        toQuery.account_id &&
        fromQuery.account_id &&
        toQuery.account_id !== fromQuery.account_id
      ) {
        this.getAllUnitList(toQuery.account_id);
      }
    },
    unit(val) {
      if (!val) {
        return;
      }
      if (this.componentKey == "dash_widgets") {
        this.$emit("input", val);
        return;
      }
      const routeMappings = {
        events: "unit-events",
        activity: "unit-activity",
        videos: "unit-videos",
        trips: "unit-trips",
        history: "unit-history",
        services: "unit-services"
      };

      const selectedMenu = this.selectedMenu;
      if (routeMappings[selectedMenu]) {
        this.$router
          .push({
            name: routeMappings[selectedMenu],
            query: { ...this.$route.query, id: val }
          })
          .catch((err) => {});
      }
    }
  },
  props: ["value", "selectedMenu", "accountId", "componentKey", "isDisabled"],

  methods: {
    search(filter) {
      this.debounce(filter);
    },

    debounce(filter) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllUnitList(this.$route.query.account_id, filter);
      }, 500);
    },
    async getAllUnitList(id, filter) {
      try {
        this.showLoading = true;
        let response = await new UnitService().getUnitListV2Lite({
          page: 1,
          page_size: 100,
          account_id: id || localStorage.getItem("USER_ACCOUNT_ID")
        });
        this.showLoading = false;
        if (response && response.data) {
          this.unitList = response.data.list;
          this.unitList = [
            ...new Map(this.unitList.map((item) => [item["id"], item])).values()
          ];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.unit-filter-avatar,
.vs__dropdown-option {
  .b-avatar-img img {
    width: 24px !important;
    height: 24px !important;
    object-fit: contain !important;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
.unit-search-input .vs--open {
  .vs__selected-options .vs__selected {
    opacity: 0 !important; /* Set initial opacity */
  }
}
.dark-layout {
  .dash {
    [dir="ltr"] .vs__spinner {
      border-right: 0.9em solid rgb(255 238 238 / 98%) !important;
      border-left: 0.9em solid rgb(234 237 231) !important;
    }
    [dir] .vs__spinner {
      border-top: 0.9em solid rgb(255 251 251) !important;
    }
  }
}
.unit-search-input {
  .vs__selected-options {
    max-width: calc(100% - 30px);
  }
}

.allocation-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: auto !important;
  display: block;
}
</style>
<style lang="scss" scoped>
.vs__selected {
  .unit-search-w {
    white-space: nowrap;
    max-width: calc(100% - 6px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 820px) {
  .vs__selected {
    .unit-search-w {
      white-space: nowrap;
      max-width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
