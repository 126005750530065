<template>
  <div>
    <b-form-checkbox
      v-model="isChecked"
      @change="updateCheckboxState"
      :disabled="isEditable"
    >
      {{ label }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  props: ["name", "desc", "label", "type", "component", "value", "isEditable"],
  components: { BFormCheckbox },
  data() {
    return {
      isChecked: false,
      isIndeterminate: false
    };
  },
  mounted() {
    this.isChecked = this.value;
  },
  methods: {
    updateCheckboxState() {
      this.$emit("input", this.isChecked);
    }
  }
};
</script>
