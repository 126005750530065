<template>
  <div
    class="tag-select"
    :class="$route.name === 'view-unit' ? 'disabledUnitIcon' : ''"
  >
    <v-select
      v-if="availableTags && availableTags.length"
      v-model="selectedTags"
      multiple
      taggable
      :placeholder="$t('alert.Labels')"
      :class="
        !checkAbility({
          action: constants.PERMISSIONS_ACTION.ADD,
          subject: constants.PERMISSIONS_MODEL.LABELS
        }) ||
        !checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.LABELS
        })
          ? 'disabled'
          : ''
      "
      :options="availableTags"
      :reduce="(option) => (option.name ? option.name : option)"
      @input="tagFormatter"
      menu-props="auto"
    ></v-select>
    <v-select
      v-else
      v-model="selectedTags"
      multiple
      :class="
        !checkAbility({
          action: constants.PERMISSIONS_ACTION.ADD,
          subject: constants.PERMISSIONS_MODEL.LABELS
        }) ||
        !checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.LABELS
        })
          ? 'disabled'
          : ''
      "
      taggable
      :placeholder="$t('alert.Labels')"
      menu-props="auto"
      ><template #no-options>
        {{ $t("groups.TagMultSelectPlaceHolder") }}
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from "vue-select";
import UtilityService from "@/libs/api/utility-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect
  },

  data() {
    return {
      selectedTags: [],
      availableTags: []
    };
  },
  mounted() {
    this.getSelectedTags(this.value);
    this.getAllGroups();
  },
  props: ["value"],
  watch: {
    selectedTags(old, newVal) {
      this.$emit("input", this.removeDuplicates(this.selectedTags));
    },
    value(newVal, old) {
      this.getSelectedTags(newVal);
    }
  },
  methods: {
    removeDuplicates(array) {
      var uniqueArray = [];
      var map = {};
      for (var i = 0; i < array.length; i++) {
        var element = array[i].toLowerCase(); // Convert to lowercase for case insensitivity
        if (!map[element]) {
          uniqueArray.push(array[i]);
          map[element] = true;
        }
      }
      return uniqueArray;
    },
    tagFormatter(tag) {
      // this.selectedTags = this.removeDuplicates(tag);
      // console.log(this.selectedTags, "selectedtag");
      // return;
      if (tag && tag.length) {
        this.selectedTags = tag.filter((t) => t.trim() !== "");
        let lastElement = tag && tag[tag.length - 1];
        if (!this.availableTags.includes(lastElement)) {
          for (let i = 0; i < this.availableTags.length; i++) {
            if (
              this.availableTags[i].toLowerCase() === lastElement.toLowerCase()
            ) {
              this.selectedTags.pop();
              if (!this.selectedTags.includes(this.availableTags[i])) {
                this.selectedTags.push(this.availableTags[i]);
              }
            }
          }
        }
      }
    },
    getSelectedTags(value) {
      if (value && value.length) {
        const isValidateLabels = value.every(
          (item) => typeof item === "object" && item !== null
        );

        if (isValidateLabels) {
          this.selectedTags = value.map((label) => label && label.name) || [];
        }
      }
    },

    async getAllGroups() {
      try {
        let response = await new UtilityService().getAllGroups({});

        if (response && response.data) {
          const isValidateLabels =
            response.data.list &&
            response.data.list.every(
              (item) => typeof item === "object" && item !== null
            );

          if (isValidateLabels) {
            this.availableTags =
              (response.data.list &&
                response.data.list.length &&
                response.data.list.map((label) => label && label.name)) ||
              [];
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.tag-select {
  ul.vs__dropdown-menu {
    max-height: 160px;
  }
  &.disabled {
    border-radius: 6px;
    background-color: var(--white3) !important;
  }
}
</style>
