<template>
  <div>
    <!-- Table Container Card -->
    <b-modal
      id="modal-sm-maintenance-notification"
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="no-header-modal"
      :hide-header="true"
      size="lg"
    >
      <div style="padding: 0.8rem 1.4rem"></div>
      <b-card-text>
        <div class="maintenance-notification-modal-title mb-2">
          {{ $t("Maintenance.ConfirmationTitle") }}
        </div>

        <div class="maintenance-notification-modal-sub-title">
          {{ $t("Maintenance.ConfirmationSubTitle") }}
        </div>
      </b-card-text>
      <div class="d-flex justify-content-center mb-64 mtt-50">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="redirectToNotification"
          class="mt-2 mr-1"
        >
          {{ $t("Maintenance.GoToNotification") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="close"
        >
          {{ $t("Maintenance.GoToMaintenanceList") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BMedia,
  BLink,
  BFormGroup,
  BModal,
  BCardText,
  VBTooltip
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SentInvitations",
  components: {
    BCard,
    BRow,
    BCol,

    BButton,

    BMedia,

    BLink,

    BCard,
    BCardText,

    BFormGroup,
    BModal,

    VBTooltip
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },

  data() {
    return {};
  },

  props: ["NotificationId"],
  methods: {
    close() {
      this.$bvModal.hide("modal-sm-maintenance-notification");
      this.$router.push({ name: "maintenance-reminder-list" });
    },
    redirectToNotification() {
      this.$router.push({
        name: "notification-edit",
        params: { id: this.NotificationId }
      });
    },
    copyText(elementId) {
      const textElement = document.getElementById(elementId);
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.InvitationLinkCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.error("Unable to copy text: ", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.InvitationLinkFailedCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      // Clear the selection
      window.getSelection().removeAllRanges();
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.maintenance-notification-modal-sub-title {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.maintenance-notification-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
.max-50-vh {
  max-height: 50vh;
  overflow-y: auto;
  // &.table td {
  //   padding: 10px 10px !important;
  // }
}
</style>
