import APIService from "./api";
export default class MaintenanceService extends APIService {
  service = "maintainance-service";
  async setDefaultNotifications(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "setDefaultNotifications" },
      data,
      options
    );
  }
  async getDefaultNotifications(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getDefaultNotifications" },
      data,
      options
    );
  }
  async getAllReminders(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllReminders" },
      data,
      options
    );
  }
  async deleteReminder(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteReminder" },
      data,
      options
    );
  }
  async getAllServiceCategories(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllServiceCategories" },
      data,
      options
    );
  }
  async updateReminder(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateReminder" },
      data,
      options
    );
  }
  async geMaintenanceReminder(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getReminder" },
      data,
      options
    );
  }
  async createReminder(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createReminder" },
      data,
      options
    );
  }
  async updateService(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateService" },
      data,
      options
    );
  }
  async getCompletedReminders(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getCompletedReminders" },
      data,
      options
    );
  }
  async getCompletedRemindersDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getCompletedRemindersDetails" },
      data,
      options
    );
  }
}
