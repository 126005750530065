<template>
  <div>
    <div class="modal-info-track new-date-picker report-col-p">
      <b-row class="flex-class">
        <b-col
          sm="12"
          v-for="(parameter, key) in parameters"
          :key="key"
          class="flex-class-item"
        >
          <div v-if="MAPPINGS[parameter.component]" class="mt-10-mb mt-10">
            <component
              class="days-b"
              :is="MAPPINGS[parameter.component]"
              v-bind="{
                ...parameter,
                id: unit_id,
                account_id: account_id,
                is_multiple_unit: is_multiple_unit
              }"
              v-model="parameter.value"
              @emitDataToParent="handleEmitData"
              :isGlobalFilter="isGlobalFilter"
              :isEditable="isEditable"
            >
            </component>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCard, BCardBody, BIcon, BCol, BRow } from "bootstrap-vue";
import DateRange from "./DateRange.vue";
import Checkbox from "./Checkbox.vue";
import Selection from "./Selection.vue";
import InputField from "./InputField.vue";
import Dropdown from "./Dropdown.vue";
import DateRangeDash from "./DateRangeDash.vue";
const PARAMETER_COMPONENTS = {
  DateRange,
  Checkbox,
  Selection,
  InputField,
  Dropdown,
  DateRangeDash
};

export default {
  name: "parameterComponents",
  components: { BIcon, BCard, BCardBody, BCol, BRow, ...PARAMETER_COMPONENTS },
  // props: ["messages", "message_time", "options"],
  props: {
    parameters: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isEditable: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    unit_id: {
      type: String,
      default: () => {
        return "";
      }
    },
    account_id: {
      type: String,
      default: () => {
        return "";
      }
    },
    is_multiple_unit: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isGlobalFilter: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  computed() {
    if (this.parameters && this.parameters.length) {
      this.$emit("input", this.parameters);
    }
  },
  methods: {
    handleEmitData(range) {
      this.parameters.forEach((e) => {
        if (e.name === "date_range" && e.range) {
          e.range = range;
        }
      });
      if (!range) {
        setTimeout(this.$emit("checkValidate", null), 2000);
      }
    }
  },
  watch: {
    parameters: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  data() {
    return {
      MAPPINGS: {
        dateRange: DateRange,
        checkbox: Checkbox,
        selection: Selection,
        shift: InputField,
        dropdown: Dropdown,
        dateRangeDash: DateRangeDash
      }
    };
  }
};
</script>

<style lang="scss">
.flex-class-item {
  width: 100%;
  .reportrange-text {
    margin-bottom: 0px !important;
  }
}
.days-b .btn-group {
  margin-bottom: 10px !important;
}
.modal-info-track {
  text-align: left;
}
.flex-class {
  display: flex;
  flex-wrap: wrap;
  // width: 100%;
}
.mt-10-mb {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
.flex-class-item {
  display: flex;
  align-items: flex-start;
  width: 50%;
}
.media {
  align-items: center !important;
  padding-top: 10px;
}
.report-time {
  text-align: center;
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  padding-bottom: 0;
  h6 {
    font-style: italic;
    font-size: 10px;
    text-transform: initial;
  }
}
@media only screen and (max-width: 991px) {
  .flex-class-item {
    width: 100%;
    .mt-10-mb {
      margin-top: 0px;
    }
  }
}
</style>
<style lang="scss" scoped>
.new-date-picker {
  .flex-class-item {
    position: relative;
  }
  .calendars {
    flex-wrap: nowrap;
  }
  .daterangepicker.openscenter {
    left: 0 !important;
    transform: translate(-0%);
  }
  padding: 10px;
}
@media screen and (max-width: 991px) {
  .row .flex-class-item {
    width: 100%;
  }
}
@media screen and (max-width: 556px) {
  .new-date-picker {
    .calendars {
      flex-wrap: wrap;
    }
    .daterangepicker.openscenter {
      left: 50% !important;
      transform: translate(-50%);
    }
  }
  .daterangepicker.show-calendar {
    .ranges {
      width: 100%;
    }
  }
  .row .flex-class-item {
    width: 100%;
  }
}
.ss {
  .btn-group {
    margin-bottom: 10px !important;
  }
}
.report-col-p {
  padding: 1rem !important;
}
.dash-widget-main {
  .report-col-p {
    padding: 0rem 1rem !important;
  }
  .mt-10 {
    margin-top: 10px;
  }
}
</style>
