<template>
  <div>
    <div class="mb-1">
      {{ label }}
    </div>
    <BButtonGroup
      size="sm"
      v-for="(component, index) in component_value"
      :key="index"
      class="btn-report-group"
    >
      <b-button
        :disabled="isEditable"
        @click="buttonPressed(component, index)"
        :variant="variant[index]"
        class="mr-1"
      >
        {{ component.label }}
      </b-button>
    </BButtonGroup>
  </div>
</template>

<script>
import { BButton, BButtonGroup } from "bootstrap-vue";

export default {
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "component_value",
    "value",
    "isEditable"
  ],
  components: { BButton, BButtonGroup },
  data() {
    return {
      selectionArray: [],
      variant: [],
      variants: ["outline-primary", "primary"]
    };
  },
  mounted() {
    // this.component_value.forEach((element) => {
    //   this.variant.push("outline-primary");
    // });
    this.selectionArray = this.value;
    ["mo", "tu", "we", "th", "fr", "sa", "su"].forEach((day) => {
      if (this.value.includes(day)) {
        this.variant.push(this.variants[1]);
      } else {
        this.variant.push(this.variants[0]);
      }
      this.variant = JSON.parse(JSON.stringify(this.variant));
    });
  },
  methods: {
    buttonPressed(component, position) {
      const index = this.selectionArray.indexOf(component.value);
      if (index <= -1) {
        this.variant[position] = this.variants[1];
        this.selectionArray.push(component.value);
      } else {
        this.variant[position] = this.variants[0];
        this.selectionArray.splice(index, 1);
      }
      this.variant = JSON.parse(JSON.stringify(this.variant));
      this.changeVariant(position);
      this.$emit("input", this.selectionArray);
    },
    changeVariant(position) {
      const t = this.variant[position];
      return this.variant[position];
    }
  }
};
</script>
<style>
.btn-report-group .btn {
  padding: 0.4rem;
  margin-right: 0.4rem !important;
  min-width: 3.3rem !important;
}
</style>
