<template>
  <div class="create-maintenance-ui">
    <b-card class="under-line-title">
      <div class="mb-2">
        <div>
          <span
            class="add-maintenance"
            v-if="$route.name === 'maintenance-reminder-create'"
          >
            {{ $t("Maintenance.CreateReminder") }}</span
          >
          <span
            class="add-maintenance"
            v-if="$route.name === 'maintenance-reminder-edit'"
          >
            {{ $t("Maintenance.UpdateReminder") }}</span
          >
          <span
            class="add-maintenance"
            v-if="$route.name === 'maintenance-reminder-view'"
          >
            {{ $t("Maintenance.ViewReminder") }}
          </span>
        </div>
        <div class="text-right">
          <div class="report-title d-flex justify-content-between">
            <h4 class="card-title">
              <!-- {{ $t("Maintenance.CreateReminder") }} -->
            </h4>
            <b-button
              variant="outline-primary"
              class="v2-back"
              @click="redirectList()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-divider"></div>
      <div>
        <validation-observer ref="serviceRules" #default="{ invalid }">
          <b-form class="add-maintenance-scroll">
            <div class="mr-1">
              <div class="select-unit-grp">
                <b-row>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group :label="`${$t('maintenance.selectUnit')} *`">
                      <validation-provider
                        #default="{ errors }"
                        name="unit_id"
                        vid="unit_id"
                        :rules="{
                          required: true
                        }"
                      >
                        <UnitSearch
                          class="unit-search-v2 v-2-unit wh-unit-h maintenance-unit"
                          v-model="maintenance.unit_id"
                          :accountId="accountId"
                          componentKey="dash_widgets"
                          selectedMenu=""
                          :isDisabled="
                            !isEditable ||
                            $route.name !== 'maintenance-reminder-create'
                          "
                        />
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group :label="`${$t('maintenance.ServiceName')} *`">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        :rules="{
                          required: true,
                          min: constants.MIN_MAINTENANCE_NAME,
                          max: constants.MAX_MAINTENANCE_NAME
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="name"
                            v-model="maintenance.name"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="name"
                            :placeholder="$t('maintenance.ServiceName')"
                            :disabled="
                              !isEditable ||
                              $route.name !== 'maintenance-reminder-create'
                            "
                            :class="!isEditable ? 'disabled' : ''"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" class="input-ui-design">
                    <b-form-group
                      :label="$t('maintenance.Labels')"
                      :disabled="
                        !maintenance.configuration.time.enabled || !isEditable
                      "
                    >
                      <TagMultiSelect
                        v-model="maintenance.labels"
                        :class="!isEditable ? 'disabled' : ''"
                      />
                    </b-form-group> </b-col
                ></b-row>
              </div>
              <div class="checkbox-card-odometer">
                <b-row>
                  <b-col sm="12" class="maintenance-check-box-title">
                    <b-form-checkbox
                      class="mr-0"
                      v-model="maintenance.configuration.odometer.enabled"
                      :disabled="
                        !(odometerSensorList && odometerSensorList.length) ||
                        !isEditable
                      "
                      name="is-rtl"
                      inline
                    />{{ $t("maintenance.ByOdometer") }}</b-col
                  >
                </b-row>

                <b-row>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group
                      :label="`${$t('maintenance.Interval')}${
                        maintenance.configuration.odometer.enabled ? '*' : ''
                      }`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="interval"
                        vid="interval"
                        :rules="{
                          required: maintenance.configuration.odometer.enabled
                            ? true
                            : false,
                          min_value: 0
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="name"
                            v-model="
                              maintenance.configuration.odometer.interval
                            "
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="name"
                            type="number"
                            :placeholder="$t('maintenance.Interval')"
                            :disabled="
                              !(
                                odometerSensorList && odometerSensorList.length
                              ) ||
                              !maintenance.configuration.odometer.enabled ||
                              !isEditable
                            "
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group :label="$t('maintenance.LastKM')">
                      <validation-provider
                        #default="{ errors }"
                        name="Last KM"
                        vid="Last KM"
                        :rules="{
                          required: maintenance.configuration.odometer.enabled
                            ? true
                            : false,
                          min_value: 0
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="last_value"
                            v-model="
                              maintenance.configuration.odometer.last_value
                            "
                            :min="0"
                            :disabled="
                              !(
                                odometerSensorList && odometerSensorList.length
                              ) ||
                              !maintenance.configuration.odometer.enabled ||
                              !isEditable
                            "
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="last_value"
                            type="number"
                            :placeholder="$t('maintenance.LastKM')"
                            :class="!isEditable ? 'disabled' : ''"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" class="input-ui-design">
                    <b-form-group>
                      <validation-provider
                        #default="{}"
                        name="sensor"
                        vid="sensor"
                        :rules="{
                          required: true
                        }"
                      >
                        <div class="d-flex align-items-center">
                          <label
                            class="mr-2"
                            style="font-size: inherit; line-height: 1.45"
                          >
                            {{
                              `${$t("maintenance.SelectSensor")}${
                                maintenance.configuration.odometer.enabled
                                  ? "*"
                                  : ""
                              }`
                            }}
                          </label>
                          <feather-icon
                            v-if="
                              maintenance.configuration.odometer
                                .device_sensor &&
                              (!odometerSensorList ||
                                !odometerSensorList.length ||
                                (odometerSensorList.length &&
                                  !odometerSensorList.some(
                                    (e) =>
                                      e.value ===
                                      maintenance.configuration.odometer
                                        .device_sensor
                                  )))
                            "
                            icon="InfoIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            style="margin-bottom: 1px"
                            v-b-tooltip.hover.v-primary
                            :title="$t('Maintenance.DeviceReallocated')"
                          />
                        </div>

                        <v-select
                          id="vue-select"
                          :placeholder="$t('maintenance.SelectSensor')"
                          :options="odometerSensorList"
                          v-model="
                            maintenance.configuration.odometer.device_sensor
                          "
                          :clearable="
                            odometerSensorList && odometerSensorList.length
                              ? false
                              : true
                          "
                          :disabled="
                            !(
                              odometerSensorList && odometerSensorList.length
                            ) ||
                            !maintenance.configuration.odometer.enabled ||
                            !isEditable
                          "
                          :reduce="(option) => option.value"
                          label="label"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div class="checkbox-card">
                <b-row>
                  <b-col sm="12" class="maintenance-check-box-title"
                    ><b-form-checkbox
                      class="mr-0"
                      v-model="maintenance.configuration.engine.enabled"
                      name="is-rtl"
                      :disabled="
                        !(enginSensorList && enginSensorList.length) ||
                        !isEditable
                      "
                      inline
                    />{{ $t("maintenance.ByEngineHours") }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group
                      :label="`${$t('maintenance.Interval')}${
                        maintenance.configuration.engine.enabled ? '*' : ''
                      }`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Engin interval"
                        vid="Engin interval"
                        :rules="{
                          required: maintenance.configuration.engine.enabled
                            ? true
                            : false,
                          min_value: 0
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="name"
                            :min="0"
                            v-model="maintenance.configuration.engine.interval"
                            :disabled="
                              !(enginSensorList && enginSensorList.length) ||
                              !isEditable ||
                              !maintenance.configuration.engine.enabled
                            "
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="name"
                            :placeholder="$t('Maintenance.Interval')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6">
                    <b-form-group :label="$t('maintenance.LastHR')">
                      <validation-provider
                        #default="{ errors }"
                        name="Last HR"
                        vid="Last HR"
                        :rules="{
                          required: maintenance.configuration.engine.enabled
                            ? true
                            : false,
                          min_value: 0
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="name"
                            :min="0"
                            v-model="
                              maintenance.configuration.engine.last_value
                            "
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="name"
                            :placeholder="
                              $t('maintenance.configuration.engine.last_value')
                            "
                            :disabled="
                              !(enginSensorList && enginSensorList.length) ||
                              !isEditable ||
                              !maintenance.configuration.engine.enabled
                            "
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" class="input-ui-design">
                    <b-form-group>
                      <validation-provider
                        #default="{}"
                        name="sensor"
                        vid="sensor"
                        :rules="{
                          required: maintenance.configuration.engine.enabled
                            ? true
                            : false
                        }"
                      >
                        <div class="d-flex align-items-center">
                          <label
                            class="mr-2"
                            style="font-size: inherit; line-height: 1.45"
                          >
                            {{ $t("maintenance.SelectSensor") }}

                            {{
                              maintenance.configuration.engine.enabled
                                ? "*"
                                : ""
                            }}
                          </label>
                          <feather-icon
                            v-if="
                              maintenance.configuration.engine.device_sensor &&
                              (!enginSensorList ||
                                !enginSensorList.length ||
                                (enginSensorList.length &&
                                  !enginSensorList.some(
                                    (e) =>
                                      e.value ===
                                      maintenance.configuration.engine
                                        .device_sensor
                                  )))
                            "
                            icon="InfoIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            style="margin-bottom: 1px"
                            v-b-tooltip.hover.v-primary
                            :title="$t('Maintenance.DeviceReallocated')"
                          />
                        </div>
                        <v-select
                          id="vue-select"
                          :placeholder="$t('maintenance.SelectSensor')"
                          :options="enginSensorList"
                          :reduce="(option) => option.value"
                          label="label"
                          :clearable="
                            enginSensorList && enginSensorList.length
                              ? false
                              : true
                          "
                          :disabled="
                            !(enginSensorList && enginSensorList.length) ||
                            !isEditable ||
                            !maintenance.configuration.engine.enabled
                          "
                          v-model="
                            maintenance.configuration.engine.device_sensor
                          "
                        />
                        <!-- <small class="text-danger">{{
                   errors[0] ? handleError(errors[0]) : ""
                  }}</small> -->
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div class="checkbox-card">
                <b-row>
                  <b-col sm="12" class="maintenance-check-box-title"
                    ><b-form-checkbox
                      :disabled="true"
                      v-model="maintenance.configuration.time.enabled"
                      class="mr-0"
                      @input="timeSelect"
                      name="is-rtl"
                      inline
                    />{{ $t("maintenance.TimeInterval") }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col md="6" sm="6">
                    <b-form-group
                      :label="`${$t('maintenance.Interval')}${
                        maintenance.configuration.time.enabled ? '*' : ''
                      }`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Interval"
                        vid="Interval"
                        :rules="{
                          required: maintenance.configuration.time.enabled
                            ? true
                            : false
                        }"
                      >
                        <DatePicker
                          :class="!isEditable ? 'disabled' : ''"
                          v-model="maintenance.configuration.time.interval"
                          :options="{
                            is_time: true,
                            single_date: true,
                            ranges: false,
                            opens: 'right'
                          }"
                          :label="''"
                          :minDate="
                            maintenance.configuration.time.last_value
                              ? maintenance.configuration.time.last_value
                              : getCurrentTime()
                          "
                          :isEditable="
                            !maintenance.configuration.time.enabled ||
                            !isEditable
                          "
                        />

                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" sm="6">
                    <b-form-group :label="$t('maintenance.LastServiceDate')">
                      <validation-provider
                        #default="{ errors }"
                        name="LastServiceDate"
                        vid="LastServiceDate"
                        :rules="{
                          required: maintenance.configuration.time.enabled
                            ? false
                            : false
                        }"
                      >
                        <DatePicker
                          :class="!isEditable ? 'disabled' : ''"
                          :options="{
                            is_time: true,
                            single_date: true,
                            opens: 'right'
                          }"
                          :maxDate="
                            maintenance.configuration.time.interval
                              ? maintenance.configuration.time.interval
                              : getCurrentTime()
                          "
                          :isEditable="
                            !maintenance.configuration.time.enabled ||
                            !isEditable
                          "
                          v-model="maintenance.configuration.time.last_value"
                          :label="''"
                        />

                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    sm="12"
                    class="maintenance-check-box-title mb-1"
                    ><b-form-checkbox
                      class="pl-0"
                      :class="
                        !maintenance.configuration.time.enabled || !isEditable
                          ? 'disabled'
                          : ''
                      "
                      :disabled="
                        !maintenance.configuration.time.enabled || !isEditable
                      "
                      v-model="maintenance.configuration.time.others.repeat"
                      name="is-rtl"
                      inline
                    /><span class="ml-1">{{
                      $t("maintenance.Repeat")
                    }}</span></b-col
                  >
                  <b-col md="6" sm="12" class="maintenance-select">
                    <b-form-group label="Every">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        vid="Name"
                        :rules="{
                          required: maintenance.configuration.time.others.repeat
                            ? true
                            : false
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <v-select
                            :class="
                              !maintenance.configuration.time.others.repeat ||
                              !maintenance.configuration.time.enabled ||
                              !isEditable
                                ? 'disabled'
                                : ''
                            "
                            v-model="
                              maintenance.configuration.time.others.date_type
                            "
                            :disabled="
                              !maintenance.configuration.time.enabled ||
                              !isEditable
                            "
                            :options="configData.frequencyOptions"
                            :reduce="(option) => option.value"
                            @input="updateDays($event)"
                            :clearable="false"
                            placeholder="Select frequency"
                          ></v-select>

                          <!-- Second Dropdown -->
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? handleError(errors[0]) : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                    v-if="
                      maintenance.configuration.time.others.date_type !==
                      'WEEKLY'
                    "
                  >
                    <b-form-group>
                      <validation-provider
                        name="Name"
                        vid="Name"
                        :rules="{
                          required: maintenance.configuration.time.others.repeat
                            ? true
                            : false,
                          min_value: 0
                        }"
                      >
                        <b-form-group :label="repeatFrequency">
                          <b-form-input
                            v-if="
                              maintenance.configuration.time.others
                                .date_type !== 'WEEKLY'
                            "
                            id="name"
                            :min="0"
                            type="number"
                            v-model="
                              maintenance.configuration.time.others.start_date
                            "
                            class="form-control-merge"
                            name="name"
                            :placeholder="`${$t('maintenance.Range')}`"
                            :disabled="!isEditable"
                            :class="
                              !maintenance.configuration.time.others.repeat ||
                              !maintenance.configuration.time.enabled ||
                              !isEditable
                                ? 'disabled'
                                : ''
                            "
                        /></b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    sm="12"
                    v-if="
                      maintenance.configuration.time.others.date_type ===
                      'WEEKLY'
                    "
                  >
                    <b-form-group class="repeater-week-group">
                      <b-form-group :label="repeatFrequency">
                        <BButtonGroup
                          size="sm"
                          v-for="(day, index) in configData.daysOfWeek"
                          :key="index"
                          class="btn-report-group mb-1"
                        >
                          <b-button
                            :disabled="
                              !maintenance.configuration.time.others.repeat ||
                              !maintenance.configuration.time.enabled ||
                              !isEditable
                                ? true
                                : false
                            "
                            class="mr-1"
                            @click="
                              (e) =>
                                (maintenance.configuration.time.others.start_date =
                                  day.value)
                            "
                            :variant="
                              maintenance.configuration.time.others
                                .start_date == day.value
                                ? 'primary'
                                : 'outline-primary'
                            "
                          >
                            {{ day.label }}
                          </b-button>
                        </BButtonGroup></b-form-group
                      ></b-form-group
                    ></b-col
                  >
                </b-row>
              </div>
              <div class="mt-2  main-p-5">
                <b-col lg="4" class="input-ui-design select-multi">
                  <b-form-group
                    :label="$t('maintenance.SelectServices')"
                    :disabled="!isEditable"
                  >
                    <validation-provider name="mobile">
                      <v-select
                        id="vue-select-1"
                        :placeholder="$t('maintenance.SelectServices')"
                        :options="categories"
                        :reduce="(option) => option.value"
                        label="type"
                        :multiple="true"
                        :class="!isEditable ? 'disabled' : ''"
                        v-model="maintenance.services"
                      >
                        <template #selected-option="{ type, icon }">
                          <b-avatar
                            :src="icon"
                            size="24"
                            variant="light-primary"
                            class="align-middle mr-50 unit-filter-avatar"
                          />

                          <span class="unit-search-w"> {{ type }}</span>
                        </template>
                        <template #option="{ type, icon }">
                          <b-avatar
                            :src="icon"
                            variant="light-primary"
                            size="24"
                            class="align-middle mr-50"
                          />

                          <span class="unit-search-w">{{ type }} </span>
                        </template>
                      </v-select>
                      <!-- <small class="text-danger">{{
                   errors[0] ? handleError(errors[0]) : ""
                  }}</small> -->
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
              <div class="mt-2 main-p-5">
                <b-col sm="12" class="maintenance-check-box-title mt-1"
                  ><span class="remember-config">{{
                    $t("maintenance.RemindersConfiguration")
                  }}</span></b-col
                >
              </div>
              <div class="d-flex flex-wrap  main-p-5">
                <b-col lg="6" md="6" sm="12">
                  <b-form-group
                    :label="$t('ReminderNotification.FirstReminder')"
                    :disabled="!isEditable"
                  >
                    <v-select
                      v-model="maintenance.reminder_config.first_reminder"
                      :options="configData.firstReminderOptions"
                      :reduce="(option) => option.value"
                      label="label"
                      :class="!isEditable ? 'disabled' : ''"
                      :placeholder="$t('ReminderNotification.FirstPlaceholder')"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                  <b-form-group
                    :label="$t('ReminderNotification.SecondReminder')"
                    :disabled="!isEditable"
                  >
                    <v-select
                      v-model="maintenance.reminder_config.second_reminder"
                      :options="configData.secondReminderOptions"
                      :reduce="(option) => option.value"
                      label="label"
                      :class="!isEditable ? 'disabled' : ''"
                      :placeholder="
                        $t('ReminderNotification.SecondPlaceholder')
                      "
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
              </div>
            </div>
          </b-form>
          <div class="d-flex justify-content-end">
            <div>
              <b-button
                :disabled="invalid || oneEnable() || isSubmitDisable"
                @click="upsertMaintenanceReminder"
                variant="primary"
                class="btn-icon"
                v-if="$route.name === 'maintenance-reminder-edit'"
              >
                <!-- <feather-icon icon="PlusIcon" size="16" /> -->

                <feather-icon icon="SaveIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-sm-inline">{{
                  $t("Maintenance.UpdateService")
                }}</span>
              </b-button>
              <b-button
                v-if="$route.name === 'maintenance-reminder-create'"
                @click="upsertMaintenanceReminder"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                :disabled="invalid || oneEnable()"
              >
                {{ $t("profile.SaveChanges") }}
              </b-button>
            </div>
          </div>
        </validation-observer>
      </div>
      <MaintenanceNotificationConfirm
        :NotificationId="NotificationId"
        v-if="NotificationId"
      />
      <Loader :show="show" />
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BLink,
  VBTooltip,
  BButtonGroup,
  BAvatar
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MaintenanceNotificationConfirm from "./MaintenanceNotificationConfirm.vue";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import moment from "moment";
import Loader from "@/layouts/components/Loader.vue";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import UnitService from "@/libs/api/unit-service";
import DateRange from "@/views/Reports/parameterComponent/DateRange.vue";
import DatePicker from "./DatePicker.vue";
import UnitSearch from "../Unit/UnitTracking/shared/UnitSearch.vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import ParameterComponents from "../Reports/parameterComponent/ParameterComponents.vue";
import AuthService from "@/libs/api/auth-service";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox,
    TagMultiSelect,
    BLink,
    VBTooltip,
    DateRange,
    UnitSearch,
    ParameterComponents,
    BButtonGroup,
    BAvatar,
    DatePicker,
    MaintenanceNotificationConfirm
  },
  data() {
    return {
      isMobileValid: true,
      accountId: localStorage.getItem("USER_ACCOUNT_ID"),
      selectedUnit: "",
      show: false,
      constants,
      NotificationId: null,
      isEditable: false,
      odometerSensorList: [],
      enginSensorList: [],
      labels: [],
      firstReminder: null,
      allUsers: [],
      secondReminder: null,
      repeatFrequency: "f",
      configData: {},
      categories: [],
      isSubmitDisable: false,
      maintenance: {
        unit_id: "",
        service_id: null,
        name: "",
        labels: [],
        configuration: {
          odometer: {
            type: "odometer",
            enabled: false,
            interval: "",
            last_value: 0,

            device_sensor: ""
          },
          engine: {
            type: "engine",
            enabled: false,
            interval: "",
            last_value: 0,
            device_sensor: ""
          },
          time: {
            type: "time",
            enabled: true,
            interval: this.getCurrentTime(),
            last_value: null,
            others: {
              date_type: "DAILY",
              start_date: "1",
              repeat: false
            }
          }
        },
        services: [],
        reminder_config: {
          first_reminder: null,
          second_reminder: null
        }
      }
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  mounted() {
    this.getConfigData();
    this.getAllServiceCategories();
    if (
      ["maintenance-reminder-view", "maintenance-reminder-edit"].includes(
        this.$route.name
      ) &&
      this.$route.params.id
    ) {
      this.geMaintenanceReminder(this.$route.params.id);
    }
    if (this.$route.name === "maintenance-reminder-view") {
      this.isEditable = false;
    } else {
      this.isEditable = true;
    }
  },
  computed() {},
  watch: {
    $route(to, from) {
      if (this.$route.name === "maintenance-reminder-view") {
        this.isEditable = false;
      } else {
        this.isEditable = true;
      }
    },
    async "maintenance.unit_id"(val, old) {
      [
        {
          key: "odometer",
          value: ["ODOMETER"]
        },
        { key: "engine", value: ["ENGINE_HOURS"] }
      ].forEach(async (sensor) => {
        await this.getDeviceSensors(sensor);
      });
      if (old && val && old != val) {
        this.maintenance.configuration.odometer = {
          type: "odometer",
          enabled: false,
          interval: "",
          last_value: 0,

          device_sensor: ""
        };
        this.maintenance.configuration.engine = {
          type: "engine",
          enabled: false,
          interval: "",
          last_value: 0,
          device_sensor: ""
        };
      }
    }
  },
  methods: {
    updateDays(e) {
      const data = this.configData.frequencyOptions.find(
        (filed) => filed.value === e
      );
      this.repeatFrequency = data.label;
      this.maintenance.configuration.time.others.start_date = 1;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.serviceRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async getDeviceSensors(sensor) {
      try {
        this.showLoading = true;
        let response = await new UnitService().getUnitWiseSensors({
          page: 1,
          page_size: 100,
          account_id: this.accountId,
          unit_id: this.maintenance.unit_id,
          filters: [
            {
              field: "all",
              value: sensor.value,
              operator: "IN"
            }
          ]
        });
        this.showLoading = false;
        if (response && response.data) {
          if (sensor.key === "odometer") {
            this.odometerSensorList = response.data.options;
          } else if (sensor.key === "engine") {
            this.enginSensorList = response.data.options;
          }
        } else if (response && !response.error && response && !response.data) {
          if (sensor.key === "odometer") {
            this.odometerSensorList = [];
          } else if (sensor.key === "engine") {
            this.enginSensorList = [];
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    timeSelect(e) {
      if (
        !e &&
        this.maintenance &&
        this.maintenance.configuration &&
        this.maintenance.configuration.time &&
        this.maintenance.configuration.time.others &&
        this.maintenance.configuration.time.others.repeat
      ) {
        this.maintenance.configuration.time.others.repeat = false;
      }
    },
    async upsertMaintenanceReminder() {
      try {
        this.showLoading = true;
        let response;
        if (this.isSubmitDisable) {
          return;
        }
        this.isSubmitDisable = true;
        await this.validationForm();
        if (
          this.maintenance.configuration &&
          this.maintenance.configuration.time &&
          this.maintenance.configuration.time.enabled &&
          this.maintenance.configuration.time.interval &&
          this.maintenance.configuration.time.last_value
        ) {
          const timeInterval = moment(
            this.maintenance.configuration.time.interval
          );
          const timeLastValue = moment(
            this.maintenance.configuration.time.last_value
          );
          if (timeLastValue.isSameOrAfter(timeInterval)) {
            this.isSubmitDisable = false;
            throw {
              message: this.$t(
                "Maintenance.TimeIntervalGraterThanTimeIntervalError"
              )
            };
          }
        }

        let message = this.$t("Maintenance.ServiceReminderCreatedSuccess");

        if (!this.$route.params.id) {
          response = await new MaintenanceService().createReminder({
            ...this.maintenance
          });
        } else {
          response = await new MaintenanceService().updateReminder({
            ...this.maintenance,
            id: this.$route.params.id
          });
          message = this.$t("Maintenance.ServiceReminderUpdateSuccess");
        }

        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });

          if (
            response.data &&
            response.data.notification_id &&
            !this.$route.params.id
          ) {
            this.NotificationId =
              response.data && response.data.notification_id;
            setTimeout(() => {
              this.$bvModal.show("modal-sm-maintenance-notification");
            }, 100);
          } else {
            this.$router.push({ name: "maintenance-reminder-list" });
          }
        } else if (response && response.error && response.error.message) {
          this.isSubmitDisable = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isSubmitDisable = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    oneEnable() {
      if (
        this.maintenance.configuration &&
        !this.maintenance.configuration.odometer.enabled &&
        !this.maintenance.configuration.engine.enabled &&
        !this.maintenance.configuration.time.enabled
      ) {
        return true;
      } else if (
        this.maintenance.configuration &&
        this.maintenance.configuration.odometer &&
        this.maintenance.configuration.odometer.enabled &&
        !this.maintenance.configuration.odometer.device_sensor
      ) {
        return true;
      } else if (
        this.maintenance.configuration &&
        this.maintenance.configuration.engine &&
        this.maintenance.configuration.engine.enabled &&
        !this.maintenance.configuration.engine.device_sensor
      ) {
        return true;
      }
    },
    async getConfigData() {
      try {
        this.showLoading = true;
        let response = await new AuthService().getConfigData();
        this.showLoading = false;
        if (response && response.data) {
          this.configData = response.data;
          this.updateDays("DAILY");
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async geMaintenanceReminder(id) {
      try {
        this.showLoading = true;
        let response = await new MaintenanceService().geMaintenanceReminder({
          id
        });
        this.showLoading = false;
        if (response && response.data) {
          const data = response.data;
          this.maintenance = {
            unit_id: data.unit_id,
            service_id: data.service_id,
            name: data.name,
            labels: data.labels,
            configuration: {
              odometer: data.configuration.odometer,
              engine: data.configuration.engine,
              time: data.configuration.time
            },
            services: data.services,
            reminder_config: data.reminder_config
          };

          this.$route.meta.breadcrumb = [
            ...constants.MAINTENANCE_REMINDERS_BREADCRUMB
          ];
          if (this.$route.name === "maintenance-reminder-edit") {
            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: {
                    name: "maintenance-reminder-view",
                    params: { id: id }
                  },
                  active: true,
                  text: response.data.name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.name
            });
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getAllServiceCategories() {
      try {
        this.showLoading = true;
        let response = await new MaintenanceService().getAllServiceCategories();
        this.showLoading = false;
        if (response && response.data) {
          this.categories = response.data.categories;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    redirectList() {
      //this.$router.push({ name: "maintenance-list" });
      this.$router.go(-1);
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@media screen and (min-width: 991px) {
  .maintenance-unit {
    .v-select.vs--single .vs__selected {
      margin-top: 0px !important;
    }
    .vs__dropdown-toggle {
      padding: 0px !important;
    }
  }
}

.select-multi .v-select .vs__selected {
  width: auto !important;
}
.index-1 {
  z-index: 2;
  position: relative;
}
.index-2 {
  z-index: 1;
  position: relative;
}
.add-maintenance {
  position: absolute;
  // width: 185px;
  height: 22px;
  font-weight: 500;
  font-size: 17.99px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  color: #5e5873;
}
.dark-layout {
  .add-maintenance {
    color: #fff;
  }
}
.update-maintenance {
  position: absolute;
  width: 175px;
  height: 22px;
  display: flex;
  align-items: center;
  color: var(--primary);
}
.custom-card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.maintenance-status {
  .form-group {
    margin-bottom: 0px;
    margin-top: 10px;
  }
}
.page-header-area {
  .header-area {
    padding: 1rem;
  }
}
.login-account-top {
  margin-top: 30px;
}
.login-account-height {
  height: 79px;
}
@media screen and (max-width: 600px) {
  .login-account-top {
    margin-top: 5px;
  }
  .login-account-height {
    height: 29px;
  }
}
.create-maintenance-h {
  height: 79px;
}
.maintenance-status-css {
  top: 30px;
}
.maintenance-h {
  height: 79px;
}
</style>
<style lang="scss" scoped>
.add-maintenance-scroll {
  max-height: calc(100vh - 250px) !important;
  min-height: calc(100vh - 250px) !important;
  overflow-y: auto;
  overflow-x: hidden;

  margin-bottom: 10px !important;
}
.create-maintenance-ui {
  .card-body {
    min-height: calc(100vh - 135px) !important;
  }
}
@media only screen and (max-width: 991px) {
.add-maintenance-scroll {
  .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
  }
  .add-maintenance {
    top:15px;
  }
  .main-p-5{
    .col-sm-12{
      padding-left: 0.5rem;
      padding-left: 0.5rem;
    }
    .input-ui-design.select-multi.col-lg-4 {
      padding-left: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}
@media only screen and (max-width: 767px) {
  .card-body {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    min-height: calc(100vh - 155px) !important;
  }
}
.maintenance-select .v-select {
  min-width: 100%;
}

.remember-config {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 500;
  line-height: 21.59px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  color: #5e5873;
}
.maintenance-check-box-title {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.report-title {
  margin-bottom: 0;

  .card-title {
    margin-bottom: 0px;
  }
}
.dark-layout {
  .checkbox-card {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    background-color: var(--dark-bg);
    border-radius: 6px;
    margin-top: 25px;
  }
}
.dark-layout {
  .checkbox-card-odometer {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: var(--dark-bg);
    padding: 0rem 1rem 1rem 1rem;
    border-radius: 6px;
  }
}
.checkbox-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 6px;
  margin-top: 25px;
}
.checkbox-card-odometer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0rem 1rem 1rem 1rem;
  border-radius: 6px;
  margin-right: 6px;
  margin-left: 6px;
}
.select-unit-grp {
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 6px;
  margin-right: 6px;
}
.repeater-week-group {
  width: 105%;
}
.badge-status-maintenance {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}

</style>
