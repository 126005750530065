<template>
  <div>
    <b-row>
      <b-col sm="12" md="4">
        <b-form-group :label="label">
          <b-form-input
            :placeholder="desc"
            name="reportName"
            v-model="inputValue"
            trim
            :disabled="isEditable"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="4">
        <b-form-group label="Select start time">
          <v-select
            id="vue-select"
            v-model="selectedTime"
            :options="timeOptions"
            placeholder="From"
          ></v-select
        ></b-form-group>
      </b-col>
      <b-col sm="12" md="4">
        <b-form-group label="Select End time">
          <v-select
            id="vue-select"
            v-model="selectedEndTime"
            :options="timeOptions"
            :disabled="!selectedTime"
            placeholder="To"
          ></v-select>
        </b-form-group> </b-col
    ></b-row>
    <!-- <b-form-group :label="label">
      <b-form-input
        :placeholder="desc"
        name="reportName"
        v-model="inputValue"
        trim
        :disabled="isEditable"
      />

      <v-select
        id="vue-select"
        v-model="selectedTime"
        :options="timeOptions"
        label="Select start time"
      ></v-select>
      <v-select
        id="vue-select"
        v-model="selectedEndTime"
        :options="timeOptions"
        :disabled="!selectedTime"
        label="Select end time"
      ></v-select>
    </b-form-group> -->
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["name", "desc", "label", "type", "component", "value"],
  components: { BFormGroup, BFormInput, BRow, BCol, vSelect },
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "parameterDataChanged",
    "value",
    "isEditable"
  ],
  data() {
    return {
      inputValue: null,
      selectedEndTime: null,
      selectedTime: null,
      timeOptions: []
    };
  },
  // computed() {
  //   this.generateTimeOptions();
  // },
  mounted() {
    // this.inputValue = this.value;
    if (this.value) {
      [this.inputValue, this.selectedTime, this.selectedEndTime] =
        this.value.split("/");
    }
    this.generateTimeOptions();
  },
  watch: {
    selectedEndTime() {
      this.validateTime();
    }
  },
  methods: {
    updateCheckboxState() {
      this.$emit("input", this.isChecked);
    },
    validateTime() {
      if (this.selectedEndTime) {
        if (this.selectedTime > this.selectedEndTime) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("ReportsData.InvalidShift"),
              text: this.$t("ReportsData.EndTimeValidation"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return;
        }
        this.$emit(
          "input",
          `${this.inputValue}/${this.selectedTime}/${this.selectedEndTime}`
        );
      }
    },
    generateTimeOptions() {
      const times = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedHour = hour.toString().padStart(2, "0");
          const formattedMinute = minute.toString().padStart(2, "0");
          const timeString = `${formattedHour}:${formattedMinute}`;
          times.push(timeString);
          this.timeOptions.push(timeString);
        }
      }
      // this.timeOptions = times;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
