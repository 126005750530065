var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-select",class:_vm.$route.name === 'view-unit' ? 'disabledUnitIcon' : ''},[(_vm.availableTags && _vm.availableTags.length)?_c('v-select',{class:!_vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.ADD,
        subject: _vm.constants.PERMISSIONS_MODEL.LABELS
      }) ||
      !_vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
        subject: _vm.constants.PERMISSIONS_MODEL.LABELS
      })
        ? 'disabled'
        : '',attrs:{"multiple":"","taggable":"","placeholder":_vm.$t('alert.Labels'),"options":_vm.availableTags,"reduce":function (option) { return (option.name ? option.name : option); },"menu-props":"auto"},on:{"input":_vm.tagFormatter},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}}):_c('v-select',{class:!_vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.ADD,
        subject: _vm.constants.PERMISSIONS_MODEL.LABELS
      }) ||
      !_vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
        subject: _vm.constants.PERMISSIONS_MODEL.LABELS
      })
        ? 'disabled'
        : '',attrs:{"multiple":"","taggable":"","placeholder":_vm.$t('alert.Labels'),"menu-props":"auto"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("groups.TagMultSelectPlaceHolder"))+" ")]},proxy:true}]),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }